<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2022-02-07 09:18:48
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-12 23:02:06
-->
<template>
  <el-dialog title="二维码预览" :visible.sync="show" width="30%">
    <ul class="flex row-around pt20">
      <li>
        <div class="flex-column row-center mb20">
          <img class="w200 h200 mb20" :src="getQr()" />
          <span class="lb-1 f-h5-grey">请扫码预览H5项目</span>
        </div>
      </li>
      <li>
        <div class="flex-column row-center">
          <img class="w200 h200 mb20" :src="WXCode" />
          <span class="lb-1 f-h5-grey">请扫码预览小程序项目</span>
        </div>
      </li>
    </ul>
    <div class="mt40">
      <el-input placeholder="请输入内容" v-model="url">
        <template slot="append"><el-button type="primary" icon="el-icon-link" @click="link">打开</el-button></template>
      </el-input>
      <h3 class="mt5 mb10 f12 f-h5-grey">PC / H5链接，可跳转直接在浏览器查看,请打开链接</h3>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { createWXcode } from '@/api/project';
import global from '@/config/global';
import viewUrl from '@/utils/viewUrl';
import http from '@/utils/cloud';

export default {
  data() {
    return {
      show: false,
      WXCode: '',
      url: '',
      viewUrlRes: '',
    };
  },

  computed: {
    ...mapGetters(['project']),
  },

  methods: {
    open() {
      this.show = true;
      this.getWXQr();
    },
    link() {
      window.open(this.url);
    },
    getQr() {
      this.url = `${this.viewUrlRes}/pages/index/tabbar/home?projectId=${this.project._id}`;

      let options = {
        padding: 10, // 二维码四边空白（默认为10px）
        width: 160, // 二维码图片宽度（默认为256px）
        height: 160, // 二维码图片高度（默认为256px）
        correctLevel: QRErrorCorrectLevel.H, // 二维码容错level（默认为高）
        reverse: false, // 反色二维码，二维码颜色为上层容器的背景颜色
        background: '#ffffff', // 二维码背景颜色（默认白色）
        foreground: '#000000', // 二维码颜色（默认黑色）
      };
      console.log('预览地址:' + this.url);
      return jrQrcode.getQrBase64(this.url, options);
    },

    async getWXQr() {
      http
        .POST('/jw-admin', {
          module: 'project',
          operation: 'createWXcode',
          projectId: this.project._id,
        })
        .then(response => {
          this.WXCode = response.fileID;
          console.log(this.WXCode);
        });
    },
  },
  async created() {
    this.viewUrlRes = await viewUrl();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: none !important;
}
</style>
