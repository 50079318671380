<!--
 * @Description: 首页容器
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:20:52
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-03 21:20:05
-->
<template>
  <div class="main">
    <!-- 侧边菜单 -->
    <ul class="main-meau">
      <img
        class="w80 auto-center mt10 mb30 radius"
        src="../../assets/image/title.gif"
      />
      <template v-for="(item, index) in meauList">
        <router-link :to="{ path: item.path }">
          <li
            :class="
              develop == 1 && index == 3
                ? 'main-meau-item bor'
                : 'main-meau-item'
            "
          >
            <i class="icon" :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </li>
        </router-link>
      </template>
    </ul>

    <!-- 内容 -->
    <div class="main-body">
      <top-bar></top-bar>
      <div class="main-body-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import { mapMutations, mapGetters } from "vuex";
import { settingProject } from "@/utils/auth";

export default {
  name: "mall",
  components: {
    TopBar,
  },
  data() {
    return {
      meauList: [],
      develop: localStorage.getItem("developers"),
    };
  },
  created() {
    // 默认设置首页为当前正在构建页面
    // this.setHomePage();

    // 利用闭包保存project数据,在刷新时保存
    let temp = this.project;

    // 当页面刷新时，把当前项目数据保存localStorage中
    window.addEventListener("beforeunload", function (e) {
      settingProject(temp);
    });

    // 添加开发者模式
    let develop = localStorage.getItem("developers");
    if (develop == 1) {
      let list = [
        // {
        //   name: '配置',
        //   icon: 'el-icon-monitor',
        //   path: '/mall/store'
        // },
        {
          name: "页面",
          icon: "icon-fuzhiyemian",
          path: "/mall/pages-manage",
        },
        {
          name: "构建",
          icon: "el-icon-view",
          path: "/mall/page-build",
        },
        {
          name: "模板",
          icon: "icon-sousuo",
          path: "/mall/model-manage",
        },
      ];
      this.meauList = list.concat(this.meauList);
    }
    this.meauList.unshift({
      name: "首页",
      icon: "el-icon-pie-chart",
      path: "/mall/home",
    });

    // 初始化列表
    let proList = [
      {
        name: "产品",
        icon: "el-icon-umbrella",
        path: "/mall/commodity",
      },

      {
        name: "服务",
        icon: "el-icon-service",
        path: "/mall/service",
      },
      {
        name: "项目",
        icon: "el-icon-folder",
        path: "/mall/project",
      },
      {
        name: "案例",
        icon: "el-icon-coin",
        path: "/mall/case",
      },
      {
        name: "售后",
        icon: "el-icon-chat-dot-round",
        path: "/mall/sale",
      },
      {
        name: "咨询",
        icon: "el-icon-chat-dot-round",
        path: "/mall/message",
      },
      {
        name: "加盟",
        icon: "icon-my",
        path: "/mall/feedback",
      },

      {
        name: "培训",
        icon: "icon-daojishi",
        path: "/mall/video",
      },

      {
        name: "招聘",
        icon: "el-icon-user",
        path: "/mall/advertise",
      },
      {
        name: "资讯",
        icon: "el-icon-discount",
        path: "/mall/article/",
      },
      {
        name: "帮助",
        icon: "el-icon-warning-outline",
        path: "/mall/help",
      },
      {
        name: "公告",
        icon: "el-icon-tickets",
        path: "/mall/content",
      },
      {
        name: "导航",
        icon: "el-icon-data-board",
        path: "/mall/advert",
      },
      {
        name: "公司",
        icon: "el-icon-star-off",
        path: "/mall/team",
      },
      {
        name: "版权",
        icon: "el-icon-mouse",
        path: "/mall/configure",
      },
      // {
      //   name: '系统',
      //   icon: 'el-icon-setting',
      //   path: '/mall/system'
      // }
    ];
    if (this.project.type == "mall") {
      this.meauList = this.meauList.concat(proList);
    }
  },
  computed: {
    ...mapGetters(["project"]),
  },

  methods: {
    ...mapMutations(["setHomePage"]),
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  height: 100vh; /*no*/
  background: #f7f8fa;

  .main-body {
    flex: 1;
    background: #222222;

    .main-body-content {
      width: 100%;
      position: relative;
      background: #ffffff;
      height: calc(100vh - 60px); /*no*/
      overflow-y: scroll;
      border-radius: 20px 0 0 0;
    }
  }
}

.main-meau {
  width: 100px; /*no*/
  height: 100%;
  padding-top: 16px; /*no*/
  background: #222222;
  overflow-y: scroll;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  .bor {
    border-bottom: 2px solid #f5f5f5;
  }
  .main-meau-item {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px; /*no*/
    padding: 11px 20px;
    color: #999999;
    cursor: pointer;

    i {
      margin-right: 5px;
      font-size: 18px; /*no*/
      color: #999999;
    }
  }

  .active {
    i {
      color: #ffffff !important;
    }

    .main-meau-item {
      position: relative;
      background: $color-theme;
      color: #ffffff;

      &::after {
        position: absolute;
        top: 10px; /*no*/
        right: 10px; /*no*/
        content: "";
        width: 4px; /*no*/
        height: 21px; /*no*/
        border-radius: 3px; /*no*/
        background: $color-theme;
      }
    }
  }
}
</style>
