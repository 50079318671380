<!--
 * @Description: 实时预览弹窗
 * @Autor: zhangzhang
 * @Date: 2021-07-05 17:05:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-12 22:54:40
-->
<template>
  <div class="RealTimeView">
    <el-dialog v-if="mShow" :append-to-body="true" :visible.sync="mShow" width="415px" top="8vh">
      <phone-ctn :head="false">
        <iframe id="cover" v-if="mShow && viewUrlRes" ref="iframe" class="screen" :scrolling="false" :src="iframeSrc" @load="load"></iframe>
      </phone-ctn>
    </el-dialog>
  </div>
</template>

<script>
import global from '@/config/global';
import { mapGetters } from 'vuex';
import viewUrl from '@/utils/viewUrl';
export default {
  name: 'RealTimeView',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mShow: false,
      viewUrlRes: '',
    };
  },
  async created() {
    this.viewUrlRes = await viewUrl();
  },
  computed: {
    ...mapGetters(['project']),

    iframeSrc() {
      let url = `${this.viewUrlRes}/pages/index/tabbar/home?operate=realTime`;
      console.log(url, '预览地址2');
      return url;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mShow = newValue;
      },
    },
    mShow: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit('update:show', newValue);
      },
    },
  },

  methods: {
    load() {
      this.$nextTick(() => {
        this.$refs['iframe'] && this.$refs['iframe'].contentWindow.postMessage(this.project, '*');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ctn {
  width: 375px;
  margin: 0;

  .ctn-height-tag {
    display: none;
  }
}

.screen {
  width: 375px /*no*/;
  height: 667px /*no*/;
  border: 0;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
}

::v-deep .PhoneCtn {
  width: 414px;
  margin: 0;

  .preview-height-tag {
    display: none;
  }

  .preview-body {
    height: 667px;
    // 隐藏滚动条
  }
}
</style>
