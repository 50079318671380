<!--
 * @Description: PC头部菜单
 * @Autor: zhangzhang
 * @Date: 2021-05-19 14:07:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-11 11:47:40
-->
<template>
  <div>
    <div class="topBar">
      <!-- 左侧内容 -->
      <span class="f14 pointer cor" @click="back">⏎返回</span>

      <!-- 右侧内容 -->
      <div>
        <span v-if="developers == 1">项目id：{{ project._id }}</span>
        <!-- 开发者模式 -->
        <el-button size="small" :class="developers == 1 ? 'bt ' : 'bt'" @click="developersChange">{{ developers == 2 ? '打开开发者模式' : '关闭开发者模式' }}</el-button>
        <el-button v-if="developers == 1" size="small ml10" @click="previewPC">PC 实时预览</el-button>
        <el-button v-if="developers == 1" size="small ml10" @click="preview">H5 实时预览</el-button>
        <el-button v-if="developers == 1" size="small ml10" @click="viewQr">生成二维码</el-button>
        <el-button v-if="developers == 1" size="small f-white bg-theme" class="bt" @click="openSave">保存发布</el-button>
        <el-button v-if="developers == 1" size="small" @click="toSchema">schema 生成器</el-button>
        <el-button size="small ml10" @click="onLogout">退出</el-button>
      </div>
    </div>

    <real-timeView :show.sync="show"></real-timeView>
    <real-timeView-pc :show.sync="PCshow"></real-timeView-pc>

    <save-dialog ref="save"></save-dialog>

    <qr-dialog ref="qr"></qr-dialog>
  </div>
</template>

<script>
import RealTimeView from './RealTimeView.vue'
import RealTimeViewPc from './RealTimeViewPc.vue'
import SaveDialog from '@/components/SaveDialog'
import QrDialog from '@/components/QrDialog'
import jrQrcode from 'jr-qrcode'
import { mapGetters, mapMutations } from 'vuex'
import { editProject } from '@/api/project'

export default {
  components: {
    RealTimeView,
    RealTimeViewPc,
    SaveDialog,
    QrDialog
  },
  provide() {
    return {
      topBar: this
    }
  },
  data() {
    return {
      show: false,
      PCshow: false,
      developers: 2
    }
  },
  computed: {
    ...mapGetters(['project', 'userInfo'])
  },
  mounted() {
    // 获取开发者模式状态
    let develop = localStorage.getItem('developers')
    if (develop) {
      this.developers = develop
    } else {
      this.developers = 2
    }
  },
  methods: {
    ...mapMutations(['logout']),

    // 开发者模式
    developersChange() {
      if (this.developers == 1) {
        this.$confirm('是否开启管理员模式功能?', '退出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.developers = 2
          this.$message.success('已开启管理员模式')
          localStorage.setItem('developers', 2)
          location.reload()
        })
      } else {
        this.$confirm('是否开启开发者模式功能?', '退出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.developers = 1
          this.$message.success('已开启开发者模式')
          localStorage.setItem('developers', 1)
          location.reload()
        })
      }
    },
    // 返回商城管理
    back() {
      const routerType = localStorage.getItem('routerType')
      if (routerType == 'admin') {
        this.$router.push('/admin/home/data')
      } else {
        this.$router.push('/managet')
      }
    },
    previewPC() {
      this.PCshow = true
    },
    preview() {
      this.show = true
    },
    openSave(view = false) {
      if (process.env.NODE_ENV == 'production') {
        if (this.project.del == 2) {
          this.$message.error('演示项目不可操作，请切换项目')
          return
        }
      }
      this.$refs.save.open(view)
    },
    toSchema() {
      let { href } = this.$router.resolve({
        path: '/schema'
      })
      window.open(href)
    },
    viewQr() {
      if (process.env.NODE_ENV == 'production') {
        if (this.project.del == 2) {
          this.$message.error('演示项目不可操作，请切换项目')
          return
        }
      }
      this.$confirm('如不更新，则预览为上次保存的项目数据?', '是否更新保存', {
        confirmButtonText: '更新保存',
        cancelButtonText: '不更新',
        type: 'warning'
      })
        .then(() => {
          this.openSave(true)
        })
        .catch(() => {
          this.openQr()
        })
    },

    openQr() {
      this.$refs.qr.open()
    },

    // 保存
    async save() {
      let data = {
        id: this.project.id,
        userId: this.userInfo.userId,
        name: this.project.name,
        richText: JSON.stringify(this.project)
      }

      let { status } = await editProject(data)

      if (status == '10000')
        this.$notify({
          title: '成功',
          message: '项目保存成功',
          type: 'success'
        })
    },

    // 退出
    onLogout() {
      this.$confirm('是否确定退出平台?', '退出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.logout()
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 0 20px;
  background: #222222;
  position: relative;
  left: -2px;
  box-shadow: 6px 0px 5px 1px rgb(0 0 0 / 10%);
  .cor {
    color: #999999;
  }
  .bt {
    background: #155bd4;
    color: #ffffff;
  }
  button {
    color: #999999;
    background: #222222;
  }
}
</style>
